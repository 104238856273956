import { createApp } from 'vue';
import siteHeader from '@components/siteHeader';
import mobileMenuStore from '@stores/mobileMenu';

export default async function (rootContainerEl = document.getElementById('v-site-header')) {
  await mobileMenuStore.dispatch('common/fetchHolidayLocationParks');

  createApp(siteHeader)
    .use(mobileMenuStore)
    .mount(rootContainerEl);
}
