import { createApp } from 'vue';
import mobileMenu from '@components/mobileMenu';
import mobileMenuPanel from '@components/mobileMenuPanel';
import mobileMenuStore from '@stores/mobileMenu';

export default function (rootContainerEl = document.getElementById('v-mobile-menu')) {
  mobileMenuStore.commit('setToken', rootContainerEl.dataset.token);
  mobileMenuStore.commit('setSchema', rootContainerEl.dataset.schema);

  createApp(mobileMenu)
    .component('mobile-menu-panel', mobileMenuPanel)
    .use(mobileMenuStore)
    .mount(rootContainerEl);
}
