import storageAvailable from '@modules/storageAvailable';

export default {
  delimiters: ['${', '}'],
  name: 'Mobile Menu',
  data() {
    return {
      sticky: false,
      currentPanel: {
        id: 0,
        title: 'Main Menu',
      },
      previousPanel: null,
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    menuVisible() {
      return this.$store.state.menuVisible;
    },
    menuNodes() {
      return this.$store.state.nodes;
    },
    mobileMenuClass() {
      const classList = [];
      if (this.menuVisible) {
        classList.push('l-mobile-menu--active');
      }
      if (this.$store.state.activeMenuPanel === 0) {
        classList.push('l-mobile-menu--home');
      }
      return classList;
    },
  },
  watch: {
    isMobile: {
      immediate: true,
      handler(isMobile) {
        if (isMobile) {
          if (!this.menuNodes.length) {
            this.initMenu();
          }
          return;
        }
        this.hideMenu();
      }
    }
  },
  methods: {
    initMenu() {
      if (storageAvailable('sessionStorage') && sessionStorage.menuNodes) {
        this.$store.commit('setNodes', JSON.parse(sessionStorage.menuNodes));
      } else {
        this.$store.dispatch('getMainMenu');
      }
    },
    hideMenu() {
      return this.$store.commit('hideMenu');
    },
  },
  template: '#t-mobile-menu',
}
