import { defineAsyncComponent, nextTick } from 'vue';

/** @param {HTMLElement} element */
function getFocusableElements(element) {
  return [
    ...element.querySelectorAll(
      'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
    ),
  ].filter(
    el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
  );
}

/** @param {HTMLElement} element */
function getLastFocusableElement(element) {
  const focusableEls = getFocusableElements(element);
  return focusableEls[focusableEls.length - 1];
}

// Load jumboNav module on demand
let jumboNavPromise = null;
const getJumboNav = () => {
  if (!jumboNavPromise) {
    jumboNavPromise = import('@modules/jumboNav');
  }
  return jumboNavPromise;
};

let menuItems = null;
let cleanUpModule = null;

export default {
  delimiters: ['${', '}'],
  name: 'Site Header',
  components: {
    // Desktop-only components
    JumboNavLocations: defineAsyncComponent(() => import('@sfc/Components/JumboNavLocations.vue')),
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isMobileMenuOpen() {
      return this.$store.state.menuVisible;
    }
  },
  watch: {
    isMobile: {
      immediate: true, // Run handler on first render
      handler(val) {
        if (!val) {
          // Start loading jumboNav module while waiting for next tick
          getJumboNav();

          nextTick(() => {
            getJumboNav()
              .then(({ default: jumboNav, cleanUp }) => {
                menuItems = document.querySelectorAll('.c-nav-bar__item');
                const menuPanels = document.querySelectorAll('.c-jumbo-nav');
                jumboNav(menuItems, menuPanels);
                cleanUpModule = cleanUp;
              });
          });
          return;
        }

        // Clean up for the last initialisation of jumboNav, which will have added event listeners
        // for elements which may no longer be in the DOM
        if (cleanUpModule && menuItems) {
          cleanUpModule(menuItems);
        }
      }
    },
  },
  methods: {
    toggleMobileMenu() {
      this.$store.commit(this.isMobileMenuOpen ? 'hideMenu' : 'showMenu');
    },
    /**
     * Manage focus when the navPanel is open
     * Pressing the esc key or tabbing outside the menu will close it
     * @param {KeyboardEvent} event
     */
    manageFocus(event) {
      // Close menu if escape key is pressed
      if (event.keyCode === 27) {
        this.$store.commit('hideMenu');
        this.$refs.mobileMenuToggle.focus();
        return;
      }
      // Close menu if tabbed outside
      if (event.keyCode === 9 && !event.shiftKey && document.activeElement === getLastFocusableElement(this.$refs.navPanel)) {
        this.$store.commit('hideMenu');
      }
    },
  }
};
