import { createStore } from 'vuex';
import common from '@stores/modules/common';
import getMedia, { breakpoints } from '@modules/breakpoints';
import storageAvailable from '@modules/storageAvailable';

/** @type {MediaQueryList} */
const mobileMenuMedia = getMedia(breakpoints.mobileMenu);

let fetchAbort = null;

const query = `{
  navigationNodes (level: 1, navHandle: "mainMenu") {
    id
    title
    type
    url
    level
    children {
      id
      title
      type
      url
      level
      customAttributes {
        attribute
        value
      }
      children {
        id
        title
        type
        url
        level
        customAttributes {
          attribute
          value
        }
        children {
          id
          title
          type
          url
          level
          customAttributes {
            attribute
            value
          }
          children {
            id
            title
            type
            url
            level
            customAttributes {
              attribute
              value
            }
          }
        }
      }
    }
  }
}`;

const store = createStore({
  modules: {
    common,
  },
  state () {
    return {
      isMobile: mobileMenuMedia.matches,
      menuVisible: false,
      activeMenuPanel: 0,
      token: null,
      schema: null,
      nodes: [],
    };
  },
  // like a computed - compute derived state based on store state
  getters: {},
  // synchronous only
  mutations: {
    refreshIsMobile(state, media) {
      state.isMobile = media.matches;
    },
    showMenu(state) {
      state.menuVisible = true;
    },
    hideMenu(state) {
      state.menuVisible = false;
      state.activeMenuPanel = 0;
    },
    setToken(state, token) {
      state.token = token;
    },
    setSchema(state, schema) {
      state.schema = schema;
    },
    setNodes(state, nodes) {
      state.nodes = nodes;
      if (storageAvailable('sessionStorage') && !sessionStorage.menuNodes) {
        sessionStorage.setItem('menuNodes', JSON.stringify(nodes));
      }
    },
    setActiveMenuPanel(state, id) {
      state.activeMenuPanel = id;
    },
  },
  // anything async must be handled as an action
  actions: {
    getMainMenu(context) {
      if (fetchAbort) fetchAbort.abort();
      fetchAbort = new AbortController();
      try {
        this.isErrorState = false;
        fetch('/api', {
          signal: fetchAbort.signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${context.state.token}`,
          },
          body: JSON.stringify({
            query,
          }),
        }).then((r) => r.json())
          .then((response) => {
            if (response.errors) {
              response.errors.map((error) => console.error(error));
            }
            this.nodes = response.data.navigationNodes;
            context.commit('setNodes', this.nodes);
          }).catch((error) => {
            console.error(error);
          });
      } catch (e) {
        console.error('getMobileMenu error:', e);
      }
    },
  },
});

// Update isMobile value on window media query listener
const refreshStateMobile = (media) => store.commit('refreshIsMobile', media);
mobileMenuMedia.addEventListener ? mobileMenuMedia.addEventListener('change', refreshStateMobile) : mobileMenuMedia.addListener(refreshStateMobile);

export default store;
