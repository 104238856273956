// Commons
import '@commons/siteHeader';

// Components
import '@styles/components/c-header-utility.scss';
import '@styles/components/c-header-main.scss';
import '@styles/components/c-header-mobile.scss';
import '@styles/components/c-jumbo-nav.scss';
import '@styles/components/c-nav-bar.scss';
import '@styles/components/c-logo-lovat.scss';

// Top banner carousel
const banners = document.querySelectorAll('.js-top-banner-carousel'); // Currently only 1
if (banners.length) {
  import('@modules/topBannerCarousel')
    .then(({ default: init }) => init(banners))
    .catch((error) => console.error(error));
}
