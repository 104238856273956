/*
  NOTE there's an potential opportunity to import this component dynamically in the mobileMenu
  instance, similar to how to the @sfc/JumboNavLocations component is imported in the siteHeader
  instance. It would probably need to be fetched eagerly though eg. in the created() hook if
  isMobile is true, otherwise the component would be fetched when the menu is opened, which would
  cause a delay in rendering the menu. The gains from this may be minimal though as it would only
  affect desktop devices.
*/

export default {
  delimiters: ['${', '}'],
  props: {
    id: {
      type: String,
      default: '0',
    },
    nodes: {
      type: Array,
      default: null,
    },
    previousPanel: {
      type: Object,
      default: null,
    },
    currentPanel: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    isPanelActive() {
      return this.$store.state.activeMenuPanel === parseInt(this.$props.id, 10);
    },
    isMenuVisible() {
      return this.$store.state.menuVisible;
    },
  },
  methods: {
    showMenuPanel(id) {
      this.$store.commit('setActiveMenuPanel', parseInt(id, 10));
    },
    hideMenuPanel(id) {
      this.$store.commit('setActiveMenuPanel', parseInt(id, 10));
    },
    isPassive(node) {
      return node.type && node.type.includes('PassiveType');
    },
    isHeading(node) {
      if (!node.customAttributes) return false;
      const matched = node.customAttributes.filter((item) => (item.value === 'heading'));
      return matched.length > 0;
    },
    hasChildren(node) {
      return node.children && node.children.length > 0;
    },
    isLast(children) {
      const childrenArray = Array.from(children);
      let lastChild;
      childrenArray.forEach((element) => {
        lastChild = element.children.length === 0;
      });
      return lastChild;
    },
  },
  template: '#t-mobile-menu-panel',
}
